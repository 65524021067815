.prestation {
  .carousel {
    overflow: hidden;

    padding-bottom: 10vh;
  }

  .inner.mobile {
    display: none;
  }

  .inner {
    height: 450px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // white-space: nowrap;
    transition: ease-in-out 1s;
    
  }

  .carousel-item {
    display: inline-flex;
    justify-content: end;
    width: 100%;
    padding: 1rem;
    color: #fff;
  }

  .indicators {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .indicators > button {
    cursor: pointer;
    margin: 5px;
    padding: 0.5rem 1.8rem;
    background: #777061;

    box-shadow: 0px 3px 4px $black;
    font-size: 1rem;
    color: $white;
    border-radius: 2px;
    border: 2px solid $white;

    background-color: $white;

    color: #777061;
  }

  .indicators > button:hover {
    cursor: pointer;

    color: $white;
    background: #777061;
  }

  .indicators > button.active {
    background: #777061;
    color: $white;
  }

  .carouselContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    width: 100%;
    height: 400px;
    color: #777061;
    background: rgb(217, 223, 223);
    background: #fff;
    box-shadow: 5px 3px 4px $black;

    margin: 0 auto;
    margin-top: 1rem;

  
  }
.carouselActivityContainer{
  display: flex;
  height: 100%;
  width: 100%;
}
  
.informationsContainerActivity{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;




  a{
    color: black;
    padding: .5rem;
  }
}
  .imageCarousel {
    width: 50%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
 .carouselContent{
margin-top: 5rem;
}
  .carouselContentAcceuil{
    margin-top: 0;
    height: 550px;
    background: none;
    box-shadow:  none;
    color: white;
  }

  // .imageCarousel::before {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background-image: inherit;
  //   background-position: center;
  //   background-size: contain;
  //   opacity: 0.6;
  //   z-index: 0;
  //   top: 0;
  //   left: 0;
  // }

  .MoreActivities {
    display: none;
  }

  .mobileIndicator {
    display: none;
  }
}



  @media screen and (max-width: 1224px) {
    .prestation {
    .carousel {
      overflow: hidden;
      width: 100%;
      padding-bottom: 0;
    }

    .prestationContent{
      gap: 0;
    }

    .carouselContent {
      margin-top: 0;
    }

    .indicators {
      display: none;
    }
    .inner.mobile {
      display: flex;
      height: 100%;
    }
    .inner.desktop {
      display: none;
    }
    .mobileIndicator {
      display: block;
      i {
        border: 1px solid $white;
        background: #777061;
        background: rgb(217, 223, 223);
        padding: 0.2rem 2rem;
        font-size: 1.3rem;
        color: #777061;
        border: 1px solid #777061;
        box-shadow: 2px 3px 5px $black;
        margin: 0 1rem;
      }
    }

  
.carouselContent{
    height: 600px;
    h2{
     font-size: 1.2rem;
     margin-top: 0.5rem;
    }
  }

  .carouselContentAcceuil{
    height: 400px;
  }
  .carouselActivityContainer{
    display: flex;
    flex-direction: column;
  }

  .informationsContainerActivity{
    width: 100%;
  }
  .imageCarousel{
    width: 100%;

    
  }
  .imageTop{

    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 200px;
  }
}


}