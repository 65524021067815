.monthDisplay {
  text-align: center;
  color: #238c95;
}

.calendarsContainer {
  margin: auto;
  background-color: #fff;
  width: 100%;
}

.displayMonth {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
}

.daysRowContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid black;
  justify-content: flex-end;
  align-items: end;
  width: 100%;
}

.calendar {
  --main-color: #1a8fff;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --border-color: black;
  --bg-color: white;
  --neutral-color: #fff;

  display: block;
  position: relative;
  width: 90%;
  background: var(--neutral-color);

  border: 1px solid var(--border-color);
}
.row {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  text-transform: none;
}

.col-start {
  justify-content: flex-start;
  text-align: center;
}
.col-center.first::after {
  content: "";
  position: absolute;
  top: 0;
  width: 5px;
  height: 8%;
  background-color: #238c95;
}

.evenMonth::before {
  content: "";
  position: absolute;
  top: 0;
  width: 38px;
  height: 8%;
  background-color: #8ad0e0;
}

.eventMonthText{
  color: #8ad0e0;
}
.oddMonth::before {
  content: "";
  position: absolute;
  top: 0;
  width: 38px;
  height: 8%;
  background-color: #9ee08a;
}

.oddMonthText{
 color: #49db1d;
}
.col-center {
  display: flex;
  flex-direction: column;

  justify-content: center;
  text-align: center;
  flex-wrap: wrap;

  max-width: 20.14284%;
  font-size: 1em;
  border-left: 1px solid black;
  border-right: 1px solid black;

  background: #bdd7ee;
  background: #f69b50;
  background: #bdfff4;
  p {
    font-size: 1rem;
  }

  span {
    border: none;
    color: #238c95;
  }
}
.col-center.people1 {
  background: none;
}
.col-center.people {
  background: none;
  p {
    font-size: 0.6rem;
  }

  i {
    margin-top: 0.5rem;
  }
}
.col.cell.people {
  text-align: center;

  border-bottom: 1px solid black;
}
.col-center.weekend {
  background: #9bedc0;
}

.col-end {
  justify-content: flex-end;
  text-align: center;
}

/* Calendar */

.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  padding: 1.5em 0;
  border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header .icon:hover {
  transition: 0.25s ease-out;
  color: var(--main-color);
}

.calendar .header .icon:first-of-type {
  margin-left: 1em;
}

.calendar .header .icon:last-of-type {
  margin-right: 1em;
}

.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: black;
  width: 89.5%;
}

.calendar .body .cell {
  position: relative;
  border-left: 1px solid var(--border-color);
  overflow: hidden;
  transition: 0.25s ease-out;
  font-size: 1.5em;
}

.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}

.calendar .body .selected {
  background: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
}

.calendar .body .selected:hover {
  background: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
}
.calendar .body .today {
  border-left: 10px solid transparent;
  border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
  border-image-slice: 1;
}
.calendar .body .booked {
  background: url("../../../../public/assets/images/cross.png") center/cover;
  pointer-events: none;
}

.calendar .body .booked::before {
  content: "";
  position: absolute;
  width: 150%;
  height: 100%;
  background: rgba(8, 8, 8, 0.164);
  background: rgba(0, 0, 0, 0.443);
}

.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell:last-child {
  border-right: none;
}

.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}

.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: var(--main-color);
  opacity: 0;
  font-size: 8em;
  position: absolute;
  top: -0.2em;
  right: -0.05em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}

.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}
.calendar .body .cell.today .bg {
  color: #ff1a79;
  opacity: 0.05;
}

.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 32);
  width: calc(100% / 32);
}

#manava_bloc_1
  > div:nth-child(6)
  > div.manava_bloc_hebs
  > div.manava_hebs
  > div
  > div.manava_col2
  > div
  > div.manava_lh
  > span.manava_lh_nbmax
  > span:nth-child(3) {
  margin-left: -2rem;
}
#manava_bloc_1
  > div:nth-child(6)
  > div.manava_bloc_hebs
  > div.manava_hebs
  > div
  > div.manava_col2
  > div
  > div.manava_lh
  > span.manava_lh_nbmax
  > span:nth-child(4) {
  margin-left: -0.936rem;
}
#manava_bloc_1
  > div:nth-child(6)
  > div.manava_bloc_hebs
  > div.manava_hebs
  > div
  > div.manava_col2
  > div
  > div.manava_lh
  > span.manava_lh_nbmax
  > span:nth-child(5) {
  margin-left: -0.872rem;
}

.saisonContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid;
}
.saisons {
  display: flex;
  justify-content: flex-end;
  width: 84.2%;
}

.lowSaison {
  width: calc(100% / 32);
  height: 15px;
  background-color: #1a8fff;
}
.foireDeBourail {
  width: calc(100% / 32);
  height: 15px;
  background-color: #fd6e15;
}
.holidaysSaison {
  width: calc(100% / 32);
  height: 15px;
  background-color: orange;
}

@media screen and (max-width: 1500px) {
  .calendar {
    width: 95%;
    margin: auto;
  }
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 14);
    width: calc(100% / 14);
  }
  .saisonContainer{
    justify-content: flex-end;
  }
  .saisons{
    width: 89.5%;
  }

  .lowSaison {
    width: calc(100% / 14);
    height: 15px;
    background-color: #1a8fff;
  }
  .foireDeBourail {
    width: calc(100% / 14);
    height: 15px;
    background-color: #fd6e15;
  }
  .holidaysSaison {
    width: calc(100% / 14);
    height: 15px;
    background-color: orange;
  }
}
@media screen and (max-width: 744px) {
  .calendar {
    width: 95%;
    margin: auto;
  }
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 10);
    width: calc(100% / 10);
   
  }

  .calendar .days {
    width: 89.5%;
  }

  .calendar .body .cell {
    min-height: 1.2em;
  }
  .calendar .header {
    font-size: 0.8em;
  }

  .calendar .header .icon:first-of-type {
    margin-left: 0em;
  }

  .calendar .header .icon:last-of-type {
    margin-right: 0em;
  }

  .monthMobile {
    display: block;
  }
  .col-start,
  .col-end {
    text-align: center;
  }
  .col-center {
    width: 10%;
  }
  /**************Saisons******************/
  .saisonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    border-bottom: 1px solid;
  }
  .saisons {
    display: flex;
    justify-content: flex-end;
    width: 89.5%;
  }

  .lowSaison {
    width: calc(100% / 10);
    // width: 20.14284%;
    height: 8px;
    background-color: #1a8fff;
  }
  .foireDeBourail {
    width: calc(100% / 10);
    // width: 20.14284%;
    height: 8px;
    background-color: #fd6e15;
  }
  .holidaysSaison {
    width: calc(100% / 10);
    // width: 20.14284%;
    height: 8px;
    background-color: orange;
  }
}
