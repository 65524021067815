@import url("https://fonts.googleapis.com/css?family=Expletus+Sans&display=swap");

.tabContainer {
  display: flex;
  min-width: 600px;
  height: calc(var(--doc-height) - 9vh);
  max-height: 100vh;
}

.tabContainerChildren {
  min-width: 70px;
  margin: 0 1px;
  transition: 0.5s ease-in;
  // overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}


.tabContainerChildren.active {
  flex-grow: 100;
  max-width: 85vw;
  margin: 0px;
  background-size: auto 100%;
}


.tabContainerChildren.active {
  .image#reservation{
    .deskptopOngletLink{
      display: none;
    }
  }
}
.tabContainerChildren:not(.active) {
  flex-grow: 1;
  max-width: 70px;
  overflow: hidden;
  opacity: 0.6;
  cursor: pointer;

  &:hover {
    opacity: 1;
    transition: none;
  }
}

.image {
  position: relative;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
}
.tabContainerChildren:not(.active) .image {
  overflow: hidden;
  opacity: 0.8;
}

.tabContainerChildren .image:hover{
  opacity: 1;
}

.text-container {
  width: 100%;
  height: 100%;
  padding: 2rem;
  visibility: hidden;
  transform: translateY(300px);
  opacity: 0;
  background: linear-gradient(354deg, black 0%, transparent 30%);
  color: $white;
  overflow: hidden;
}
.text {
  position: absolute;
  bottom: 5rem;
  width: 100%;
  padding-top: 40rem;
}

.titleOnglet {
  position: absolute;
  color: $white;
  font-size: 1.3em;
  font-family: "Roboto";
  background: #897f68;
  color: $white;
  font-weight: bold;
  max-width: 12vw;
  padding: 1rem;
  writing-mode: vertical-lr;
  text-orientation: upright;
}

.text:hover {
  transform: scale(1);
  transition: 0.5s ease;
}
.active .text-container {
  visibility: visible;
  transform: translateY(0px);
  opacity: 1;
}

.mobileOngletLink {
  display: none;
}
.deskptopOngletLink {
  display: block;
  height: 100%;
}

@media screen and (max-width: 1204px) {
  .tabContainer {
    height: var(--doc-height);
  }
}
@media screen and (max-width: 1024px) {
  .tabContainer {
    flex-direction: column;
    margin: 0;
    height: var(--doc-height);
    min-width: 0px;
    .mobileOngletLink {
      display: block;
      height: 100%;
    }
    .deskptopOngletLink {
      display: none;
    }

    .titleOnglet {
      position: absolute;
      display: flex;
      color: $white;
      justify-content: center;
      align-items: center;
      font-size: 1em;
      font-family: "Roboto";
      background: #897f68;
      color: $white;
      font-weight: bold;
      max-width: 100vw;
      width: 40vw;
      height: 4vh;
      max-height: 4vh;
      padding: 0 1rem;
      writing-mode: horizontal-tb;
      text-orientation: upright;
      z-index: 50;
    }
  }

  .text-container {
    padding: 0;
    background: linear-gradient(354deg, black 10%, transparent 20%);
    //  transform: translateY(10px);
    padding-left: 24vh;
  }

  .tabContainerChildren {
    transition: all 1.2s linear;
    max-height: 76vh;
    margin: 0px;
  }

  .tabContainerChildren.active {
    max-width: 100vw;
    height: 55vh;
  }

  .tabContainerChildren:not(.active) {
    max-height: 4vh;
    min-height: 4vh;
    max-width: 100vw;
    opacity: 0.7;
    cursor: pointer;

    &:hover {
      opacity: 1;
      transition: none;
    }
  }
  .tabContainerChildren:not(.active) .image {
     overflow: visible;
     opacity: 0.8;
   }
  .active .text-container {
    visibility: visible;
    transform: translateY(0px);
    opacity: 1;
  }

  .active .titleOnglet {
    width: 400px;
    position: fixed;
    background: $white;
    color: $black;
  }

  .text {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
}


.arrow-container{
  width: 50px;
  height: 50px;
  margin: 0 auto;
  position: absolute;
  bottom: 5px;
  // left: 0;
  right: 0;
}

.arrow-1{
  width: 50px;
  height: 50px;
  background: #f2a766;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
}

.arrow-2{
  width: 30px;
  height: 30px;
  background: #f2a766;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  display: table;
}

.arrow-2:before{
  width: 24px;
  height: 24px;
  content: "";
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
}

.arrow-2 i.fa{
  font-size: 15px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: black;
}

/* Custom Animate.css */

.animated.hinge {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
          animation-iteration-count:infinite
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.4, .4, .4);
            transform: scale3d(.4, .4, .4);
  }

  50% {
    opacity: 0.5;
  }
  
  100% {
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(.4, .4, .4);
            transform: scale3d(.4, .4, .4);
  }

  50% {
    opacity: 0.5;
  }
  
  100% {
    opacity: 0;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}