.carouselKev {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  min-width: 450px;
  max-height: 1300px;
  overflow-y: hidden;
  border-radius: 12px;

  .imageCarouselKev {
    min-width: 90px;
    margin: 1px;
    min-height: 400px;
    height: 400px;
    position: relative;
  }

  @keyframes scroll {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(calc(-400px * 2.2));
    }
    100% {
      transform: translateY(0);
    }
  }
  $animationSpeed: 60s;

  .slide {
    animation: scroll $animationSpeed linear infinite;
    animation-play-state: running;
    height: calc(400px * 5);
    display: block;
  }
}

@media screen and(max-width:1500px ) {
  .carouselKev {
    min-width: 0;
  }
}

@media screen and(max-width:1224px ) {
  .carouselKev {
    flex-direction: row;
    $animationSpeed: 60s;
    overflow-x: hidden;

    .slide {
      display: flex;
      flex-direction: row;
      animation: scrollMobile $animationSpeed linear infinite;
      animation-play-state: running;
      width: calc(400px * 3.2);
      height: 400px;

      .imageCarouselKev {
        min-width: 400px;
      }
    }
  }

  @keyframes scrollMobile {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(calc(-400px * 5));
    }
    100% {
      transform: translateX(0);
    }
  }
}
