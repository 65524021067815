.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 50px;
  padding: 1rem;
  background-color: #1f5156;
  background-color: #777061;
  color: black;
  padding-top: 1rem;
  // text-shadow: white 0px 0px 20px;

  svg {
    fill: $white;
    height: 30px;
    width: 30px;
  }
  .BetikureFooter {
    font-family: "Kaushan Script";
    font-size: 1.5rem;
    color: $white;
    text-shadow: $white 0px 0px 20px;
  }
  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
  .BetikureAdress {
    color: black;
    text-shadow: $white 0px 0px 10px;
  }
}

.webMaster {
  position: absolute;
  right: 20px;
  font-size: 0.8rem;
}
@media screen and (max-width: 1224px) {
  .footer .BetikureFooter {
    display: none;
  }
  .BetikureAdress {
    font-size: 0.8rem;
  }
}
