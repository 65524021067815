.prestation {
  background: $background-linear;
}

.prestationContent {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin: 0 auto;
  color: $white;
  padding-bottom: 1.5rem;
  min-height: 90vh;

  p {
    margin: 1rem 0.5rem;
    // width: 50%;
    white-space: normal;
    margin-bottom: 1rem;
  }

  h1 {
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.resumePrestationTop{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  // background-color: red;
  .imageCarousel{
    width: 100%;
  }
}

.acceuilActivity{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.carouselDetails {
  background: rgb(27, 26, 26);
  padding: 1rem;
  border-radius: 12px;
}

@media screen and (max-width: 1224px) {
  .prestationContent {
    width: 100%;
  }

  .indicators {
    display: none;
  }

  .next {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      padding-top: 0.6rem;
    }

    button {
      width: 1.5rem;
      height: 1.5rem;
      animation: next 4s ease-in-out infinite alternate;
      background-color: transparent;
      border: none;
      opacity: 0.8;
    }
  }

  @keyframes next {
    to {
      transform: scale(1.5);

      transform: translateX(50%);
    }
  }
}
@media screen and (max-width: 450px) {
  .line {
    display: none;
  }
}
