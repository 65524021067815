.navbar {
  background-color: #777061;
  height: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 20;
  border-bottom: 1px solid $white;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  color: $white;
  align-items: center;
  text-shadow: white 0px 0px 20px;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.7rem;
}

.logo {
  width: 70px;
  height: 70px;
}

@media screen and (max-width: 1224px) {
  .navbar {
    display: none;
  }
}
