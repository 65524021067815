.reservation {
  background: white;
}
.reservationContent {
  width: 100%;
  margin: auto;
  // background: $background-linear;
  overflow-x: hidden;

  h2 {
    color: #f2a766;
    font-family: "Kaushan Script", cursive;
    text-align: center;
    margin: 0.5rem;
    font-size: 2rem;
  }
  h3 {
    text-align: center;
    color: $white;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
.desktopRenderDays {
  display: block;
}

.mobileRenderDays {
  display: none;
}
.tabletRenderDays {
  display: none;
}

.planning {
  margin: 1rem auto;

  p {
    text-align: center;
    font-size: 1.1rem;
    padding: 0.5rem;
  }
}

.clicForMoreInfo {
  margin: 0 auto;
  text-align: center;
  font-size: 0.8rem;
}

input[type="checkbox"] {
  margin-right: 0.5rem;
}

.iframeDiv {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column; 
  align-items: center;
  gap: 1rem;
  color: $black;
  border: 2px solid black;
  background: rgb(255, 254, 254);
  h4 {
    padding-top: 1rem;
    font-size: 1.5rem;
  }
}

.waitingForDateSelection {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
  // align-items: center;
}
.personnageSelection {
  margin-left: 3rem;
  font-size: 2rem;
  i {
    margin: 0.5rem;
    color: gray;
  }
}
.AnchorToHebergement {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 0.8rem;
  align-items: center;

  padding: 1rem;

  h3 {
    font-family: "Roboto", cursive;

    font-size: 1.6em;
    text-shadow: black 0px 0px 15px;
    color: $white;
  }

  a {
    font-size: 1.8rem;
    font-family: cursive;
    // text-decoration: none;
    text-shadow: white 0px 0px 10px;

    &:hover {
      color: black;
      transform: scale(1.1);
    }
  }
}

.modal {

  position: absolute;

  top: 47%;
  left: 50%;
  border: 2px solid $white;
  display: block;
  min-width: 20em;
  width: 55%;
  padding: 3rem 3rem 0rem 3rem;

  background-color: rgba(255, 255, 255, 0.955);

  color: black;
  border-radius: 1em;
  transform: translate(-50%, -45vh);
  outline: transparent;
  
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // border: 2px solid $white;
  // display: block;
  // min-width: 20em;

  // background-color: #363635;

  // color: black;
  // border-radius: 1em;
  // transform: translate(-50%, -45vh);
  // outline: transparent;
  z-index: 100;

  .line {
    display: none;
  }
}

.iframeModal{
  display: flex;
  justify-content: center;
}
.overlay {
  position: fixed;
  overflow: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(119, 119, 119, 0.733);
  z-index: 99;
}
.buttonModal {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  border: 1px solid black;
  border-radius: 8px;
  
  background-color: #1d1610;
}

.infoCalendar {
  display: flex;
}
.infoCalendarHebergements {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  button {
    padding: 0 1rem;
  }
}
.disponibiliteContainer {
  display: flex;
  h2 {
    margin: 0rem 1rem;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: #f2a766;
    border: 1px solid;
    padding: 0.5rem;
  }
}

.iframeRightContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end
}
.erreurHebergement{

  line-height: 1.3;
  text-align: center;
  padding: .5rem;
  width: 60%;
  margin-top:1rem;
  margin-right: 5rem;
  font-size: 0.8rem;
  background-color: #d2ffd0d6;
  border-radius: 12px;
  .erreurRed{
    background-color: #ffd0d0;
    color: #db3030;
    padding: 0.2rem;
    border-radius: 0.5rem;
  }
  a{
    color : black
  }
}
.reservationContainerh2 {
  position: absolute;
  left: 2rem;
  top: 5rem;
  margin: 0rem 1rem;
  // writing-mode: vertical-rl;
  // transform: rotate(180deg);
  color: #f2a766;
  border: 1px solid;
  padding: 0rem 1rem;
}

/*************** button toggle see all hebergement in form resa *************/
.checkbox-wrapper-6 {
  display: flex;
  gap: 1rem;
}
.checkbox-wrapper-6 .tgl {
  display: none;
}
.checkbox-wrapper-6 .tgl,
.checkbox-wrapper-6 .tgl:after,
.checkbox-wrapper-6 .tgl:before,
.checkbox-wrapper-6 .tgl *,
.checkbox-wrapper-6 .tgl *:after,
.checkbox-wrapper-6 .tgl *:before,
.checkbox-wrapper-6 .tgl + .tgl-btn {
  box-sizing: border-box;
}
.checkbox-wrapper-6 .tgl::-moz-selection,
.checkbox-wrapper-6 .tgl:after::-moz-selection,
.checkbox-wrapper-6 .tgl:before::-moz-selection,
.checkbox-wrapper-6 .tgl *::-moz-selection,
.checkbox-wrapper-6 .tgl *:after::-moz-selection,
.checkbox-wrapper-6 .tgl *:before::-moz-selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-6 .tgl::selection,
.checkbox-wrapper-6 .tgl:after::selection,
.checkbox-wrapper-6 .tgl:before::selection,
.checkbox-wrapper-6 .tgl *::selection,
.checkbox-wrapper-6 .tgl *:after::selection,
.checkbox-wrapper-6 .tgl *:before::selection,
.checkbox-wrapper-6 .tgl + .tgl-btn::selection {
  background: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 3em;
  height: 1.5em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:after,
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:after {
  left: 0;
}
.checkbox-wrapper-6 .tgl + .tgl-btn:before {
  display: none;
}
.checkbox-wrapper-6 .tgl:checked + .tgl-btn:after {
  left: 50%;
}

.checkbox-wrapper-6 .tgl-light + .tgl-btn {
  background: lightgrey;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
}
.checkbox-wrapper-6 .tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
}
.checkbox-wrapper-6 .tgl-light:checked + .tgl-btn {
  background: #f2a766;
}

/*******************************************************************/

.iframeresaDesktop {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-items: center;
  text-align: center;
  position: relative;
  gap: 1rem;
  margin-left: 3rem;
  margin-top: 6rem;
}

.errorMessage {
  margin: 1rem;
  background-color: rgba(219, 86, 86, 0.337);
  padding: 1rem;
  border-radius: 12px;
  border: 2px solid red;
}

.remiseContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
  gap: 0.5rem;

  p {
    margin-top: 0.5rem;

    i {
      margin-right: 0.2rem;
      color: rgb(228, 160, 42);
    }
  }
}

.pro_mobile_checkbox{
  display: none;
}
.remise {
  display: flex;
  gap: 1rem;
  border: 2px solid #f2a766;
  padding: 0.5rem;
  border-radius: 8px;
}

.iframeResaText {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .gg-arrow-down {
    position: absolute;
    right: -25px;
    color: #f2a766;
    transform: rotate(270deg);
  }
  .gg-arrow-down::before {
    background: #f2a766;
  }
}

.reservationDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  align-items: center;
  border: 2px solid #f2a766;
  padding: 0.5rem;
  border-radius: 8px;

  i {
    color: #333;
    margin-left: 0.2rem;
  }

  button {
    background-color: #f2a766;
    padding: 0.2rem 0.9rem;
    border-radius: 0;
    color: black;
    border: 1px solid black;
  }
}

.etapeContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem;
}
.etapeContainer.etape3 {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 7rem;
  padding-right: 1rem;

  .gg-arrow-down {
    transform: rotate(90deg);
    color: #f2a766;
  }

  .gg-arrow-down::before {
    background: #f2a766;
  }
  p {
    writing-mode: vertical-rl;
  }
}

.numberEtapeReservation {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f2a766;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 1rem;
}

.veuillezselectionnerdates {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #f2a766;
  padding: 1rem;
  border-radius: 12px;
  //  width: 50%;
  text-align: center;

  p {
    margin: 0 1rem;
  }
  .gg-arrow-down::before {
    background-color: #f2a766;
  }
  i {
    transform: rotate(180deg);
    color: #f2a766;
  }
}
.backgroundReduction{
  margin: auto;
  background-color: #badaf9;
  width: 80%;
  border-radius: 8px;
  padding: 0.5rem 0;
}
.iframeResaMobile {
  display: none;
}

.planningIframe {
  margin-left: 1rem;
}

.buttonsMonnaie {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: rgb(228, 228, 228);
    border-radius: 6px;
    padding: 0.5rem;
    color: #777061;
  }

  button.active {
    background: #777061;
    color: white;
  }
}

.cgv {
  h3 {
    width: 20%;
    border: 1px solid #f2a766;

    text-align: center;
    margin: 0 auto;
    padding: 0.5rem;
    color: #f2a766;
    margin-bottom: 0.5rem;
  }
  background-color: white;
  color: $black;
  display: grid;

  gap: 1rem;
  padding: 1rem;
  font-size: 0.8rem;

  .no-smoking-span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    border-radius: 25px;
    width: 35%;
    display: flex;
    gap: 1rem;
    margin: auto;

    .no-smoking {
      display: flex;
      align-items: center;

      background: url("../../../public/assets/images/no-smoking.png")
        center/cover;
      height: 30px;
      width: 30px;
    }
  }
}

.reservationContainerGrid {
  display: flex;
  justify-content: center;
  min-height: 40vh;
  position: relative;

  h2 {
    margin: 0rem 1rem;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    color: #f2a766;
    border: 1px solid;
    padding: 0.5rem;
  }
}

.nbPersonneSelectionContainer {
  display: flex;
}

.adulteReservationContainer {
  margin: auto;
}

.adulteEnfantContainer {
  display: flex;
  flex-direction: column;
  // margin-bottom: 2rem;
  // margin-left: 5rem;
}

/*********** button + - reservation ***/ //

.box {
  // padding-top: 1.5em;
  text-align: center;
  display: flex;

  align-items: center;
}

.quantity {
  position: relative;
  display: inline-block;
  color: #7f7f7f;

  input[type="number"] {
    transition: border 0.3s ease-in-out, color 0.3s ease-in-out;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    box-shadow: none;
    outline: none;
    width: 42px;
    height: 34px;
    text-align: center;
    float: right;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    color: #342f2f;

    &:focus {
      border-color: #57b8f6 !important;
    }

    &:hover {
      border-color: #a5a5a5;
    }
  }

  &-button {
    width: 39px;
    height: 34px;
    display: inline-block;
    float: right;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 7px);
      content: "";
      width: 14px;
      height: 2px;
      background-color: currentColor;
      display: block;
    }
  }

  &-remove {
    &::after {
      display: none;
    }
  }

  &-add {
    &::after {
      transform: rotate(90deg);
    }
  }
}
/************************************************************************/
.dateContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  p {
    font-family: cursive;

    font-size: 1.3rem;
  }
}

.annulationArray, .annulationArrayMobile {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  border: 2px solid #f2a766;
  border-radius: 8px;
  padding: 1rem;
}
.annulationArrayMobile{
  display: none;
}
@media screen and (min-width:320px) and (max-width:1500px) and (orientation:landscape) {
  .mobileRenderDays {
    display: none;
  }

  .desktopRenderDays {
    display: none;
  }

  .tabletRenderDays {
    display: block;
  }
}

@media screen and(max-width:1025px ) {
  .mobileRenderDays {
    display: none;
  }

  .desktopRenderDays {
    display: none;
  }

  .tabletRenderDays {
    display: block;
  }
  .iframeresaDesktop {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }
  .iframeRightContainer{
    align-items: center;
  }
  .erreurHebergement{
    margin-right: 0;
  }

  .disponibiliteContainer {
    display: block;

    h2 {
      margin: 0rem 1rem;
      writing-mode: horizontal-tb;
      transform: rotate(0deg);
      color: #f2a766;
      border: 1px solid;
      padding: 0.5rem;
      margin: 1rem;
    }
  }

  .reservationContainerGrid {
    display: flex;
    flex-direction: column;
    gap: 1rem;


    h2 {
      display: none;
    }
  }

  .annulationArray {
    display: none;
  }
  .annulationArrayMobile {
    display: block;
    align-items: stretch;
    td {
      max-width: 20rem;
    }
  }
}

@media screen and(max-width:450px ) {

  .reservationContent {
    width: 100%;
    margin: 0rem auto;
  }

  .calendarsContainer{
    display: grid;
    grid-template-areas: 
    "disponibiliteContainer"
    "legendContainer"
    "reservationContainerGrid"
    "pro_mobile_checkbox"
    "iframeResaMobile"
    ;
  }

  .pro_mobile_checkbox{
    grid-area: pro_mobile_checkbox;
    padding: 1rem .5rem 0 .5rem;
    font-size: .8rem;
    text-align: center;
    display: block;
  }
  .disponibiliteContainer{
    grid-area: disponibiliteContainer;
  }

  .legendContainer{
    grid-area: legendContainer;
  }
  .reservationContainerGrid{
    grid-area: reservationContainerGrid;
  }
  .iframeResaMobile{
    grid-area: iframeResaMobile;
  }
.iframeResaText{

  .adulteEnfantContainer, .reservationDetailsContainer ,.checkbox-wrapper-6{
    display: none;
  }
  }
  .mobileRenderDays {
    display: block;
  }

  .desktopRenderDays {
    display: none;
  }
  .tabletRenderDays {
    display: none;
  }

  .iframeDiv {
    margin-left: 0%;
  }
  .iframeResaDesktopIframe {
    display: none;
  }
  .AnchorToHebergement {
    flex-direction: column;
  }

  div.carousel-root > div:nth-child(2) {
    display: none;
  }
  .carousel .slider-wrapper {
    width: 99%;
    transition: ease-in-out;
  }

  .carousel-root {
    border: none;
    box-shadow: none;
    background: none;
  }

  .erreurHebergement{
    width: 100%;
    margin: 0;
    font-size: 0.6rem;
   
  }

  .planning {
    iframe {
      margin-left: 0 !important;
    }
  }

  .iframeResaMobile {
    display: block;
  }

  .reservationContainerGrid {
    min-height: 0;
  }

  .nbPersonneSelectionContainer {
    display: block;
  }

  .iframeresaDesktop {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }

  .adulteEnfantContainer {
    margin-left: 0;
  }

  .etapeContainer {
    .numberEtapeReservation {
      display: none;
    }
  }

  .remiseContainer {
    display: none;
  }

  .modal {
    position: absolute;
    top: 0%;
    left: 50%;
    border: 2px solid $white;
    display: block;

    min-width: 95%;

    color: #f00;
    background-color: #777061;
    background-color: #363635;

    border-radius: 1em;
    transform: translate(-50%, 0%);
    outline: transparent;
    z-index: 100;
  }

  .cgv .no-smoking-span {
    width: 100%;
  }

  .cgv h3 {
    width: 90%;
  }

  #xpfIframe{ 
    height: 480px!important;
  }
}

@media screen and(max-width: 1000px ) {
  .adulteEnfantContainer {
    margin: 0;

    .box {
      display: block;
    }

    .quantity {
      padding-top: 0.8rem;
    }
  }
  .modal{
    width: 100%;
    padding: 0;
    background-color: white;
  }

}
