.typeHebergement {
  border-radius: 12px;
  margin: 0rem auto;
  padding: 1rem;
  // gap: 1rem;
  color: $white;
  display: grid;
  width: 90%;

  grid-template-areas:
    "header header  "
    "image image"
    "description description"
    "plusdetails plusdetails";

  grid-template-columns: 1fr 1fr;

  i {
    width: 20px;
    margin-right: 0.5rem;
  }
  .carouselContentResa {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    margin: 0 auto;
    .indicators {
      display: none;
    }

    .imageThreeHebergement {
      width: 100%;
    }
  }
}
.slide{
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .slider-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60%;

  min-height: 700px;
  max-height: 800px;
  // margin-top: 5rem;
}



.thumb {
  width: 180px !important;
  max-height: 130px;
}


.carousel .thumb.selected {
  border: 2px solid $white;
  // border-radius: 12px;
}
.carousel .thumbs {
  white-space: pre-wrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.2rem;
  background-size: contain;

  li {
    @include verticalCenter;
    border: none;
  }
}

.carousel-root {
  padding: 1rem 0 0 0;
  grid-area: image;
  width: 100%;

display: flex;
flex-direction: column-reverse;
  margin: auto;

  img {
    background-size: cover;


  }
}
.carousel .carousel-slider{
  background-color: #ffffff1f;
}
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
  padding: 5rem;
}
.carousel .control-next.control-arrow:before {
  border-left: 12px solid #fff;
}
.carousel .control-prev.control-arrow:before {
  border-right: 12px solid #fff;
}
.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}

.titleHebergement {
  grid-area: header;
  // margin: 0 1rem 1.5rem 1rem;
  display: flex;

  flex-direction: column;
  // flex-wrap: wrap;

  align-items: center;
  gap: 0rem;

  .descriptionLogoHebergement {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  p {
    // font-size: 1.2rem;
    white-space: nowrap;
  }
}

.h2reservationCompo {
  font-family: "Kaushan Script";
  font-size: 2.5rem;
}

.descriptionTypeHebergement {
  grid-area: description;

  padding: 1.5rem;

  font-size: 1.2rem;
  border-radius: 12px;
}
button {
  cursor: pointer;
  margin: 5px;
  padding: 0.5rem 2rem;
  background: #777061;
  font-size: 1rem;
  color: $white;
  border-radius: 62px;
  border: 2px solid $white;

  position: relative;

  &:hover {
    background-color: $white;
    color: #777061;
    border: 2px solid $white;
  }
}

button.active {
  background-color: $white;
  color: #777061;
}
.detailsContainer {
  grid-area: plusdetails;

  padding: 1rem;
  display: flex;
  flex-direction: column;

  align-items: center;
  gap: 2rem;

  a {
    font-size: 1rem;

    &:hover {
      color: black;
    }
  }

  .moreDetailLink {
    cursor: pointer;
    padding: 0.3em;
    border-bottom: 1px solid $white;
    font-size: 1.4em;
  }
}
.moreDetailsFurnitures {
  grid-area: plusdetails;
  padding: 0.5rem;
  width: 100%;
  border-top: 3px solid $white;
  border-bottom: 3px solid $white;
  border-radius: 30px;
  // background-color: rgba(0, 0, 0, 0.287);
  display: flex;
  align-items: center;
  justify-content: space-around;

  flex-wrap: wrap;
  li {
    padding: 0.1rem;
    list-style: none;
  }
}

.iconsContainer {
  display: flex;
  gap: 1rem;
}

.iconsPng {
  background: url("../../../public/assets/images/terrace.png") center/contain;
  background-repeat: no-repeat;
  height: 23px;
  width: 23px;

  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(360deg)
    brightness(106%) contrast(103%);
}

.doubleBed {
  background: url("../../../public/assets/images/doublebed.png") center/contain;
}

#checkIcon {
  color: $color-1;
  margin: 0;
}

@media screen and(max-width:1224px ) {
  .typeHebergement {
    width: 100%;
    margin: 0rem auto;
    padding: 0;
    display: grid;
    // border: none;
    // box-shadow: none;
    gap: 0;

    grid-template-areas:
      "header header"
      "image image"
      " description description"
      " plusdetails plusdetails"
      "tarifsReservation tarifsReservation ";
    grid-template-columns: 1fr 1fr;
    // padding-top: 3rem;

    .descriptionLogoHebergement {
      flex-wrap: wrap;
      justify-content: center;
      padding: 0.5rem;
      gap: 0.5rem;
    }
  }
  .detailsContainer {
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .carousel-root {
    max-width: 100%;
  }


  .moreDetailsFurnitures {
    justify-content: flex-start;

    ul {
      margin: 0;
    }
  }
  .carousel .slider-wrapper {
    min-height: 0;
  }

  .detailsContainer {
    gap: 1.5em;
  }
  .descriptionTypeHebergement {
    padding: 1.5rem 1rem;
  }

  .carousel.carousel-slider .control-arrow {
   
    padding: .5rem;
  }
}
