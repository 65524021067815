.lodge {
  background: $background-linear;
  h1 {
    margin-bottom: 1rem;
  }
}

.logdeContent {
  color: $white;
  text-align: center;
  overflow-x: hidden;
  position: relative;

  h4 {
    color: wheat;
  }

  h2 {
    padding: 0.5rem;
    width: 100%;
  }

  p {
    width: 90%;
    color: $white;
  }

  .lodgeShadows {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    color: $white;
  }

  .imageOneLodge {
    background: url("../../../public/assets/images/piscinenight.jpg")
      center/cover;
    height: 400px;
    width: 100%;
  }

  .imgPlanche {
    width: 90%;
    margin-top: 2rem;
    border-bottom: 3px solid white;
  }

  .lodgeWhereTofindUs {
    @include verticalCenter;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .mapaGoogle {
    width: 100%;
  }

  @media screen and (min-width: 1224px) {
    .imageOneLodge {
      height: 500px;
      width: 80%;
    }

    .mapaGoogle {
      width: 80%;
    }

    .imgPlanche {
      width: 70%;
      margin-top: 2rem;
      border-bottom: 3px solid white;
    }
  }
}
