#hebergement {
  // scroll-behavior: smooth;
}
.hebergement {
  background: $background-linear;
padding-bottom: 1rem;

  .hebergementContent {
    padding: 1rem;

    color: $white;
    display: grid;
    align-items: center;
    gap: 1rem;
position: relative;
    grid-template-areas:
      "villa chambre "
      "villa bois "
      "suite bois "
      "hebergementPCenter hebergementPCenter";

      p{
      
        margin: 1rem;
        margin-top: 3rem;
        text-align: justify;
        line-height: 1.3rem;
      }
  }
}
.imagesHebergement{
  box-shadow: 5px 5px 15px rgba(217, 200, 200, 0.632);}
.imageLeftHebergement {
  background: url("../../../public/assets/images/beti_ext_night.jpg") center/contain;
  background-repeat: no-repeat;
  float: left;
  shape-outside: margin-box;
  margin: 1rem;
  height: 250px;
  width: 350px;
}
// .imageLeftHebergement {
//   background: url("../../../public/assets/images/beti_ext_night.jpg") center/contain;
//   height: 250px;
//   width: 350px;
//   display: block;
// }

.imageRightHebergement {
  background: url("../../../public/assets/images/plan.jpg") center/contain;
  background-repeat: no-repeat;
  float: right;
  shape-outside: margin-box;
  margin: 1rem;
  height: 300px;
  width: 320px
}
.imageChambreHebergement {
  background: url("../../../public/assets/images/room.jpg") center/contain;
  background-repeat: no-repeat;
  float: left;
  shape-outside: margin-box;
  margin: 1rem;
  height: 250px;
  width: 55%;

}

  
// .imageRightHebergement {
//   background: url("../../../public/assets/images/plan.jpg") center/contain;
//   height: 400px;
//   background-repeat: no-repeat;
//   width: 100%;
// }
.imageSuiteHebergement {
  background: url("../../../public/assets/images/cuisine.jpg") center/contain;
  background-repeat: no-repeat;
  float: right;
  shape-outside: margin-box;
  margin: 1rem;
  height: 220px;
  width: 300px;
  
}
.imageSuite2Hebergement {
  background: url("../../../public/assets/images/room2.jpg") center/contain;
  background-repeat: no-repeat;
  // float: left;
  // shape-outside: margin-box;
  // margin: 1rem;
  height: 300px;
  width: 430px;
  
}
.imageBoisNoirGrid {
  background: url("../../../public/assets/images/boisnoir.jpg") center/contain;
  background-repeat: no-repeat;
  float: left;
  shape-outside: margin-box;
  margin: 1rem;
  height: 200px;
  width: 300px;
  
}
.imageBoisNoir2Grid {
  background: url("../../../public/assets/images/boisnoir2.png") center/contain;
  background-repeat: no-repeat;
  float: right;
  shape-outside: margin-box;
  margin: 1rem;
  height: 200px;
  width: 300px;
  

}
.grid{
  // box-shadow: 5px 5px 15px rgba(255, 255, 255, 0.632);
// border-radius: 10px;
  // padding: 1rem;
  // box-shadow: 5px 5px 25px black;

  // p{margin-top: 4rem!important}



  H2{ 
    // border-bottom: 1px solid;
    margin-left: 1rem;
  }
}

.villaGrid {
  grid-area: villa;

}
.chambreGrid {
  margin-top: 3rem;
  grid-area: chambre;


}
.suiteGrid {
  grid-area: suite;


}
.boisGrid {
  grid-area: bois;

}
.carouselGrid {
  grid-area: carousel;
}

.AnchorToHebergement {
  grid-area: AnchorToHebergement;
}
.buttonContainerHebergement{
  position: absolute;
  transform: translate(50%,50%);
  right: 50%;
  bottom: 200px;
}

.bottomTypehHebergement {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .divOngletHebergement {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14rem;
    gap: 0.5rem;

    i {
      color: $white;
    }
  }

  .divOngletHebergement.right {
    padding-right: 6rem;
  }
  .divOngletHebergement.left {
    padding-left: 6rem;
  }

  .linkOngletHebergement {
    color: $white;
    font-size: 1.3em;
    text-align: center;
    font-family: $font-1;
    background: #777061;
    border: 1px solid $white;
    border-radius: 6px;
    color: $white;
    font-weight: bold;
    padding: 1rem;

    min-height: 30rem;
    writing-mode: vertical-lr;
    text-orientation: upright;
    text-decoration: none;
    opacity: 0.8;


    &:hover {
      opacity: 1;
    }
  }
}
.linkOngletHebergementInfo {
  color: $white;
  font-size: 1em;
  text-align: center;
  font-family: $font-1;
  background: #777061;
  border: 1px solid $white;
  border-radius: 6px;
  min-width: 20rem;
  color: $white;
  font-weight: bold;
  padding: 0.5rem;

  text-decoration: none;
  opacity: 0.8;


  &:hover {
    opacity: 1;
  }
}

.hebergementPCenter{
  border: 1px solid;
  box-shadow: 5px 5px 25px black;
  padding: 1rem;
  grid-area: hebergementPCenter;
  text-align: center;
  border-radius: 10px;
}

.bottomHebergementContainerEquipement{
  background-color: rgb(255 255 255 / 91%);
  border-radius: 15px;
  padding: 1rem;
margin: 2rem;


h3{
  margin : 1.5rem;
  margin-left: 25%;
}
}
.bottomHebergement{
  display: flex;
  gap: 2rem;

}
.ListEquipement{
  display: block;
}
.ListEquipementMobile{
  display: none;
}

@media screen and (max-width: 1224px) {

  .hebergement {
display: block;
  
    .hebergementContent {
      padding: 0rem;
      display: block !important;
      p {
        
        margin: 1rem;
      }
    }
  .grid {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      border-bottom: 2px solid;
  

      button{
        margin-bottom: 3rem;
      }

      h2{
        margin-left: 0;
      }
}



.imagesHebergement{
  box-shadow: none;
  width: 100%;
  background-size : cover;
}
.imageChambreHebergement{
  width: 100%;  
}

.buttonContainerHebergement{
  position: relative;
  display: block;
  bottom: 5px;
  right: 0;
  transform: translate(0);
  left: 25%;




}


}
.ListEquipement{
  display: none;
}
.ListEquipementMobile{
 display: block;

}
}


