@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

$font-1: "Kaushan Script", cursive;
$color-1: #61dafb;
$black: #282c34;
$white: rgb(243, 243, 243);
$background-linear: linear-gradient(to right, #134e5e, #71b280);
$background-linear: #897f68d8;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;

}

.scroller {

  scrollbar-color: $white;
}
::-webkit-scrollbar {
  width: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: $white;
  outline: 1px solid slategrey;
}
::-webkit-scrollbar-track {
  background-color: #777061;
}

h1 {
  @include verticalCenter;
  margin: 0rem auto;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  color: $white;
  border-bottom: 5px solid $white;
  width: 80%;
  font-family: "Kaushan Script", cursive;
}

a {
  color: $white;
}

@media screen and (max-width: 1024px) {
  * {
    scroll-behavior: smooth;
  }

  h1 {
    display: none;
  }
  .paddingTopMobile {
    padding-top: 4vh;
  }
  .scroller {
    // overflow-y: hidden;
    scrollbar-color: $white;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $white;
    outline: 1px solid slategrey;
  }
  ::-webkit-scrollbar-track {
    background-color: #777061;
  }
  p {
    font-size: 1em;
  }
}
