.PaimentEchecContainer {
  min-height: calc(var(--doc-height) - 9vh - 50px);
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  h1 {
    color: black;
    margin-top: 1rem;
  }

  .PaimentEchec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    line-height: 3rem;

    a {
      color: black;
    }
    .retour_ehec {
      position: absolute;
      bottom: 100px;
      right: 200px;
      color: black;
      text-decoration: none;

      &:hover {
        color: rgb(89, 84, 84);
      }
    }
    .separator_mt_2 {
      margin-top: 2rem;
    }
    .small {
      font-style: italic;
    }
  }
}
@media screen and (max-width: 1224px) {
  .PaiementEchecPage {
    .navbar {
      display: block;
      .logo {
        height: 45px;
        width: 45px;
      }
      .nav-container {
        height: auto;
      }
      .nav-logo {
        font-size: 1.4rem;
      }
    }
  }
  .PaimentEchecContainer {
    padding: 1rem;
    width: 90%;
    h1 {
      display: block;
      font-size: 1.5rem;
      margin: 0.3rem;
    }

    .PaimentEchec {
      line-height: 1.2rem;
      gap: 1rem;

      .retour_ehec {
        position: static;
      }
      .separator_mt_2 {
        margin-top: 1rem;
      }
    }
  }
}
